import React, { useState, useEffect, useContext } from "react";
import "../../../../assets/css/followup/followup.scss";
import LogoBasedOnDomain from "../../../Includes/Layouts/Common/LogoBasedOnDomain";
import FollowUpFooter from "../FollowUpFooter";
import FollowUpLoader from "../FollowUpLoader";
import { useHistory } from "react-router-dom";
import { queryString, urlParams,getQueryString } from "../../../../Utility/QueryString";
import { useFollowUpPendingDetails } from "../../../../Hooks/useFollowUpPendingDetails";
import GetLenders from "../../../../Utility/GetLenders";
import Button from "../../../UI/Button";
import icon1 from "../../../../assets/img/followup/icon1.png";
import icon2 from "../../../../assets/img/followup/icon2.png";
import icon3 from "../../../../assets/img/followup/icon3.png";
import bannerImg from "../../../../assets/img/followup/bg-float2.png";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import { useFollowupLoad } from "../../../../Hooks/useFollowupLoad";
import { handleCreditCheckFailureFollowup, fetchCreditCheckBasicData } from "../../../../Utility/Validations";
import { useLocation } from "react-router-dom";


const FollowUpV2 = () => {
  const history = useHistory();
  const { followUpPendingDetails } = useFollowUpPendingDetails();
  const { loadFollowup } = useFollowupLoad();
  const { getLenderList } = GetLenders();
  const [loaderStatus, setLoaderStatus] = useState("show");
  const { visitorParameters } = useContext(VisitorParamsContext);

  const [followupDataStatus, setFollowupDataStatus] = useState({});
  const [uuid, setUuid] = useState();
  //const userToken = urlParams.get("atp_sub2");
  //const atpSub6 = urlParams.get("atp_sub6") ? urlParams.get("atp_sub6") : "FLP";
  const query_string = getQueryString();
  const params = new URLSearchParams(query_string);
  const atpSub6 = params.get('atp_sub6') || "FLP_DUP";
  const userToken = params.get("atp_sub2");;
  localStorage.setItem("atp_sub2", userToken);
  localStorage.setItem("atp_sub6", atpSub6);
  const [pendingDetails, setPendingDetails] = useState("");
  let validCreditCheck = handleCreditCheckFailureFollowup();
  const splitName = 'TCGL_V_A1';
  const slideChange = (e) => {
    if (!localStorage.getItem("atp_sub6")) {
      localStorage.setItem("atp_sub6", atpSub6);
    }
    if (!localStorage.getItem("atp_sub2")) {
      localStorage.setItem("atp_sub2", userToken);
    }
    let redirectUrl = "/followup-thankyou";
    switch (true) {
      case pendingDetails?.is_buyer_post == 1:
        redirectUrl = "/followup-thankyou";
        break;
      case pendingDetails?.is_bank_complete == 0:
        redirectUrl = "/followup-lenders-v2";
        break;
      case pendingDetails?.is_user_sign == 0:
        redirectUrl = "/followup-signature-v2";
        break;
      case pendingDetails?.is_credit_check == 0 && pendingDetails?.is_credit_check_failed == 0:
        redirectUrl = "/followup-credit-analysis-v2";
        break;
      case pendingDetails?.is_credit_check_failed == 1 &&
        validCreditCheck === 1:
        redirectUrl = "/followup-edit-details";
        break;
      case pendingDetails?.is_doc_complete == 0:
        redirectUrl = "/followup-id-upload-v2";
        break;
    }
    history.push(
      `${redirectUrl}?uuid=${uuid}&atp_sub6=${atpSub6}&atp_sub2=${userToken}&splitName=${splitName}`
    );
  };
  useEffect(() => {
    (async () => {
      const response = await followUpPendingDetails(query_string, null);
      if (response) {    
        if (response?.data?.duplicate === true) {
          history.push(
            "/duplicate" +
            "?uuid=" +
            response.data.uuid +
            "&splitName=" + "TCGL_V_A1" +
            "&type=" + response.data.type +
            "&key=" + response.data.key
          );
          return;
        } else if (response.data.token == false) {
          visitorParameters.visitor_parameters.uuid = response.data.uuid;
          history.push(
            "/loader" +
            "?uuid=" +
            response.data.uuid +
            "&splitName=" +
            "TCGL_V_A1" + "&nextPage=" + "TCGL_V_A1/lenders"
          );
          return;
        }
        const followupDetails = response.data.response.followup_data;
        const userUuid = response.data.response.user_info.uuid;
        if (response.data.status === "Success") {
          const flpResponse = await loadFollowup(
            "followup_load",
            followupDetails,
            "v1/followup",
            queryString,
            "v1",
            userUuid
          );
        }
        const respPendData = response.data.response.pending_details;
        const pendingArray = {
          is_user_complete: respPendData.is_user_complete,
          is_user_sign: respPendData.is_user_sign,
          is_credit_check: respPendData.is_credit_check,
          is_bank_complete: respPendData.is_bank_complete,
          is_doc_complete: respPendData.is_doc_complete,
          is_credit_check_failed: respPendData.is_credit_check_failed,
          is_buyer_post: respPendData.is_buyer_post,
        };
        const userInfo = response.data.response.user_info;
        localStorage.setItem("followup_data", JSON.stringify(followupDetails));
        localStorage.setItem(
          "pending_details",
          JSON.stringify(response.data.response.pending_details)
        );
        localStorage.setItem("pendingArrayData", JSON.stringify(pendingArray));
        setPendingDetails(pendingArray);
        if (
          respPendData.is_credit_check === 1 &&
          respPendData.is_credit_check_failed === 1 &&
          respPendData.failed_response &&
          Object.keys(respPendData.failed_response).length > 0
        ) {
          const creditCheckApiResponseDetails = {
            vendor: respPendData?.failed_response?.vendor || "",
            status: respPendData?.failed_response?.failed_error_code || "",
            message: respPendData?.failed_response?.failed_error_message || "",
          };
          localStorage.setItem(
            "creditCheckApiResponse",
            JSON.stringify(creditCheckApiResponseDetails)
          );
        }
        const userData = fetchCreditCheckBasicData(userInfo);
        localStorage.setItem("formData", JSON.stringify(userData));
        localStorage.setItem(
          "user_info",
          JSON.stringify(response.data.response.user_info)
        );
        const pendingLocalData = {
          is_user_complete: 0,
          is_user_sign: 0,
          is_credit_check: 0,
          is_bank_complete: 0,
          is_doc_complete: 0,
          is_credit_check_failed: 1,
        };

        localStorage.setItem(
          "pendingLocalData",
          JSON.stringify(pendingLocalData)
        );

        if (response?.data?.response?.user_info?.last_name) {
          localStorage.setItem(
            "sur_names",
            JSON.stringify({
              txtLName: response?.data?.response?.user_info?.last_name,
            })
          );
        }
        if (response?.data?.response?.user_info?.address_id) {
          localStorage.setItem(
            "userSelectedAddress",
            JSON.stringify({
              address1: response?.data?.response?.user_info?.address_id,
            })
          );
        }
        setFollowupDataStatus({
          is_qualified: response.data.response.user_info.is_qualified,
          is_user_complete:
            response.data.response.pending_details.is_user_complete,
          is_questionnaire:
            response.data.response.pending_details.is_questionnaire,
          is_user_sign: response.data.response.pending_details.is_user_sign,
          filled_question:
            response.data.response.pending_details.filled_question,
          user_name: response.data.response.user_info.user_name,
          user_banks: response.data.response.user_info.user_banks,
          pending_details: response.data.response.pending_details,
          is_credit_check:
            response.data.response.pending_details.is_credit_check,
          is_bank_complete:
            response.data.response.pending_details.is_bank_complete,
        });
        setUuid(userUuid);
        localStorage.setItem("uuid", userUuid);
        var userBanks = response.data.response.user_info.user_banks;
        const bankList = await getLenderList();
        const filteredBanks = bankList.filter(
          (bank) => !userBanks.includes(bank.id)
        );
        const getUserBanks = bankList.filter((bank) =>
          userBanks.includes(bank.id)
        );
        localStorage.setItem("userBankList", JSON.stringify(getUserBanks));

        const pendingCarRegistration = bankList.filter((bank) => {
          const pendingCarRegistrationList =
            response.data.response.pending_details.pending_car_registration;

          if (Array.isArray(pendingCarRegistrationList)) {
            return pendingCarRegistrationList.includes(bank.id);
          }
          return false;
        });

        localStorage.setItem(
          "pendingCarRegistration",
          JSON.stringify(pendingCarRegistration)
        );
        setLoaderStatus("hide");
      }
    })();
  }, []);
  return (
    <>
      <FollowUpLoader status={loaderStatus} />
      <div className="TGGtcg">
        <div className="bg_grade">
          <header id="header" className="sticky-header">
            <div className="container">
              <a>
                <LogoBasedOnDomain />
              </a>
            </div>
          </header>

          <section className="section_create">
            <div className="bg_clor">
              <div className="container-md " style={{ paddingTop: "90px" }}>
                <div className="row p-0 m-0 pb-4">
                  <div className="col-md-12 col-sm-12 col-lg-6">
                    <div className="content">
                      {/* {slideStatus.proceedSlide && ( */}
                      <div className="slide1">
                        <h4>Dear {followupDataStatus?.user_name}</h4>
                        <p className="banner-subtext">
                          Thank you for starting your claim. We have received
                          some information but need a few more details. Please
                          provide the missing information to proceed.
                        </p>
                        <h5 className="card_head">
                          Why Provide Essential Information?
                        </h5>
                        <div className="card_info">
                          <div className="icon">
                            <img src={icon1} alt="Icon" />
                          </div>
                          <div className="content">
                            <h3>
                              <strong>Efficient Processing: </strong>
                            </h3>
                            <p>
                              Providing all required details allows us to
                              process your claim more efficiently.
                            </p>
                          </div>
                          <div className="ribbon"></div>
                        </div>
                        <div className="card_info">
                          <div className="icon">
                            <img src={icon2} alt="Icon" />
                          </div>
                          <div className="content">
                            <h3>
                              <strong> Accurate Assessment:</strong>
                            </h3>
                            <p>
                              Complete information ensures we can provide
                              everything needed for your lender to evaluate your
                              claim accurately.
                            </p>
                          </div>
                          <div className="ribbon"></div>
                        </div>
                        <div className="card_info">
                          <div className="icon">
                            <img src={icon3} alt="Icon" />
                          </div>
                          <div className="content">
                            <h3>
                              <strong> Timely Updates:</strong>
                            </h3>
                            <p>
                              Full details enable us to keep you informed about
                              the status and progress of your claim.
                            </p>
                          </div>
                          <div className="ribbon"></div>
                        </div>
                        <div className="py-4">
                          <Button
                            type="button"
                            id="proceedBtn"
                            className="main-btn zoom p-2 w-100 mb-lg-0 mb-sm-0 py-3 fs-5 fw-bold sticky next2"
                            buttonText={
                              <>
                                {" "}
                                Proceed{" "}
                                <i
                                  className="bi bi-chevron-right bold-icon"
                                  id="proceedBtn"
                                ></i>
                              </>
                            }
                            onClick={slideChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-6 d-lg-block d-none d-sm-none text-end">
                    <div>
                      <img width="80%" src={bannerImg} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <FollowUpFooter />
      </div>
    </>
  );
};

export default FollowUpV2;
